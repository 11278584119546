import React from 'react'
import PortfolioBlockModal from './PortfolioBlockModal'
const portfolioAll = [
    {
        _id:'001',
        src:'img/airconditionings/daitsueco.jpg',
        title:'Eco',
        category:'Daitsu',
        info:'Equipo Muro Inverter, bomba de calor para climatizar en todas las estaciones del año. Disponible en 9.000, 12.000, 18.000 y 24.000 Btu/hr. La solución para tu hogar o negocio con una excelente relación precio calidad. ',
        content: [
            {
                title:'DESHUMIDIFICACIÓN',
                text: 'Reduce la humedad de la habitación.'
            },
            {
                title:'TIMER',
                text: 'Permite la programación de los horarios de funcionamiento del equipo.'
            },
            {
                title:'LIGHT',
                text: 'Apagado parcial o total de los indicadores luminosos de la unidad.'
            },
            {
                title:'SLEEP',
                text: 'Regulación de temperatura y nivel sonoro del equipo, para obtener el máximo confort y ahorro energético.'
            },
            {
                title:'CUATRO VELOCIDADES',
                text: 'Permite seleccionar diferentes velocidades de funcionamiento para adaptarse a las necesidades del usuario.'
            }
        ]
    },
    {
        _id:'002',
        src:'img/airconditionings/daitsuair.jpg',
        title:'Air',
        category:'Daitsu',
        info:'Equipo Inverter, con una eficiencia energética de Clase A ++ según la normativa europea y Clase A según normativa local, garantiza un óptimo balance entre temperatura y confort, aún en temperaturas extremas, con un diseño discreto y actual. Permite incorporar el accesorio wifi cuando se requiera y así ajustar y programar la temperatura desde donde estés, mediante cualquier dispositivo compatible con la aplicación EWPE Smart. ',
        content: [
            {
                title:'TURBO',
                text: 'Incrementa la potencia frigorífica o calorífica para acelerar la climatización de la habitación consiguiendo rápidamente la temperatura deseada.'
            },
            {
                title:'SWING',
                text: 'Movimiento vertical de las lamas de salida del aire de forma automática para distribuir homogéneamente el aire.'
            },
            {
                title:'DESHUMIDIFICACIÓN',
                text: 'Reduce la humedad de la habitación.'
            },
            {
                title:'TIMER',
                text: 'Permite la programación de los horarios de funcionamiento del equipo.'
            },
            {
                title:'LIGHT',
                text: 'Apagado parcial o total de los indicadores luminosos de la unidad.'
            },
            {
                title:'RESET',
                text: 'Revisa el funcionamiento del equipo y muestra el código de error en el panel de la unidad interior.'
            },
            {
                title:'ARRANQUE EN CALIENTE',
                text: 'El ventilador de la unidad interior no se activa hasta que el intercambiador haya alcanzado la temperatura seleccionada.'
            },
            {
                title:'INTELLIGENT DEFROSTING',
                text: 'Activación del desescarche de forma automática cuando es necesario, aumentando el confort y ahorro energético.'
            },
            {
                title:'WIFI',
                text: 'Control del equipo y programación mediante una red inalámbrica'
            },
            {
                title:'CUATRO VELOCIDADES',
                text: 'Permite seleccionar diferentes velocidades de funcionamiento para adaptarse a las necesidades del usuario.'
            }
        ]
    },
    {
        _id:'003',
        src:'img/airconditionings/PANELFUJITSULF.jpg',
        title:'LF',
        category:'Fujitsu',
        info:'El Split Pared Inverter LF ofrece el aire más puro y sano del mercado. Para ello incorpora filtros de última generación, compuestos de iones deodorizantes de larga duración, que reducen la oxidación y neutralizan eficazmente los posibles malos olores del ambiente. A sus vez, el filtro antibacterias, elimina cualquier microorganismo a través de electricidad estática, garantizando así un aire sano.',
        content: [
            {
                title:'ALTA EFICIENCIA Y CLIMATIZACIÓN CONFORTABLE',
                text: 'Gracias a la función "Power Diffuser" la salida de aire frío se realiza de manera horizontal para evitar la sensación de frío directo y el aire caliente se direcciona en vertical creando una sensación agradable de calefacción.'
            },
            {
                title:'FILTROS DE ÚLTIMA GENERACIÓN PARA UN AIRE PURO Y SANO',
                text: 'Los equipos incluyen un filtro de iones deodorizante de larga duración y un filtro antibacterias. Gracias a la generación de iones, el filtro reduce la oxidación y neutraliza eficazmente los posibles malos olores del ambiente. A su vez, el filtro antibacterias, por medio de la electricidad estática, elimina las pequeñas esporas, partículas y microorganismos que pueda haber, garantizando así un aire sano.'
            },
            {
                title:'FUNCIÓN "10ºC HEAT"',
                text: 'Gracias a esta función la temperatura nunca baja de 10ºC con el objetivo de asegurar que la estancia no esté excesivamente fría a nuestro retorno, y así mantener un mínimo nivel de temperatura que nos ayude a recuperar rápidamente la sensación de confort.'
            },
            {
                title:'DESHUMIDIFICADOR',
                text: 'Reducción de la humedad ambiental sin variaciones en la temperatura.'
            },
            {
                title:'AJUSTE AUTOMÁTICO DE LAS LAMAS',
                text: 'Selección automática de la posición de las lamas dependiendo de su funcionamiento. También pueden ser reguladas por control remoto.'
            },
            {
                title:'APERTURA Y CIERRE AUTOMÁTICOS DE LAS LAMAS',
                text: 'Las lamas de impulsión de aire se abren y cierran automáticamente cuando el equipo se pone en marcha o se para.'
            },
            {
                title:'POWER DIFFUSER',
                text: 'Gracias al Power Diffuser la salida de aire frío se realiza de manera horizontal para evitar la sensación de frío directo, y el aire caliente se direcciona en vertical creando una sensación agradable de calefacción.'
            },
            {
                title:'10ºC HEAT',
                text: 'Permite programar que la temperatura de la estancia no baje de 10ºC. De modo que cuando la habitación no esté ocupada, no llegue a enfriarse en exceso.'
            },
            {
                title:'CAMBIO AUTOMÁTICO FRÍO/CALOR',
                text: 'Dependiendo de la temperatura ambiental y la seleccionada, la unidad modifica automáticamente el modo de funcionamiento de frío o calor.'
            },
            {
                title:'SWING VERTICAL',
                text: 'Las lamas de salida del aire se mueven verticalmente de forma automática para distribuir homogéneamente el aire.'
            },
            {
                title:'AJUSTE AUTOMÁTICO DEL CAUDAL DE AIRE',
                text: 'El microprocesador ajusta automáticamente el caudal de aire dependiendo de las variaciones de temperatura.'
            },
            {
                title:'REINICIO AUTOMÁTICO',
                text: 'En caso de interrupción del suministro eléctrico, el equipo se inicia automáticamente una vez reestablecido.'
            },
            {
                title:'DESCONEXIÓN AUTOMÁTICA SLEEP',
                text: 'El microprocesador adecua gradualmente la temperatura a las necesidades corporales, previamente a la desconexión.'
            },
            {
                title:'PROGRAMACIÓN HORARIA COMBINADA ON-OFF',
                text: 'El programador digital permite seleccionar cualquiera de estas 4 combinaciones: ON, OFF, ON-OFF, OFF-ON.'
            },
            {
                title:'FILTRO DEODORIZADOR DE IONES',
                text: 'Reduciendo la oxidación mediante la generación de iones, este filtro neutraliza eficazmente los malos olores.'
            }
        ]
    },
    {
        _id:'004',
        src:'img/airconditionings/PANELFUJITSULMC.jpg',
        title:'LMC',
        category:'Fujitsu',
        info:'La serie LMC ofrece unidades hasta un 30% más reducidas con facilidad de mantenimiento gracias a su frontal extraíble y lavable. Además, es fiel a la filosofía Fujitsu, incorporando la función Low Noise que reducir 3dB el nivel sonoro de la unidad exterior.',
        content: [
            {
                title:'DISEÑO COMPACTO',
                text: 'Unidades con sólo 203 mm de profundidad y facilidad de mantenimiento gracias a su frontal extraíble y lavable.'
            },
            {
                title:'EVAPORADOR DE ALTA DENSIDAD',
                text: 'Con dimensiones de hasta un 30% más reducidas se consigue la máxima eficiencia.'
            },
            {
                title:'FUNCIONAMIENTO EN MÁXIMA POTENCIA',
                text: 'El equipo puede trabajar durante 20 minutos en condiciones de máximo caudal de aire y máxima velocidad del compresor, ofreciendo así su máxima potencia. Mediante esta rápida refrigeración o calefacción, conseguimos alcanzar el confort de la estancia en el menor tiempo posible.'
            },
            {
                title:'FUNCIÓN "LOW NOISE" PARA LA UNIDAD EXTERIOR',
                text: 'Se activa desde el propio mando y permite reducir a 3 dB el nivel sonoro de la unidad exterior.'
            },
            {
                title:'ENERGY SAVE',
                text: 'Gracias al control de los ajustes de temperatura del termostatos e consigue un considerable ahorro de energía.'
            },
            {
                title:'DESHUMIDIFICADOR',
                text: 'Reducción de la humedad ambiental sin variaciones en la temperatura.'
            },
            {
                title:'POWER DIFFUSER',
                text: 'Gracias al Power Diffuser la salida de aire frío se realiza de manera horizontal para evitar la sensación de frío directo, y el aire caliente se direcciona en vertical creando una sensación agradable de calefacción.'
            },
            {
                title:'FUNCIÓN POWERFUL',
                text: 'Activa el funcionamiento del equipo a máxima velocidad del compresor y máximo caudal de aire durante 20 minutos, para conseguir rápidamente una sensación de confort.'
            },
            {
                title:'10ºC HEAT',
                text: 'Permite programar que la temperatura de la estancia no baje de 10ºC. De modo que cuando la habitación no esté ocupada, no llegue a enfriarse en exceso.'
            },
            {
                title:'LOW NOISE',
                text: 'A través del propio mando a distancia se puede activar la función Low Noise, que rebaja el nivel sonoro de la unidad exterior en 3 dB.'
            },
            {
                title:'CAMBIO AUTOMÁTICO FRÍO/CALOR',
                text: 'Dependiendo de la temperatura ambiental y la seleccionada, la unidad modifica automáticamente el modo de funcionamiento de frío o calor.'
            },
            {
                title:'SWING VERTICAL',
                text: 'Las lamas de salida del aire se mueven verticalmente de forma automática para distribuir homogéneamente el aire.'
            },
            {
                title:'AJUSTE AUTOMÁTICO DEL CAUDAL DE AIRE',
                text: 'El microprocesador ajusta automáticamente el caudal de aire dependiendo de las variaciones de temperatura.'
            },
            {
                title:'REINICIO AUTOMÁTICO',
                text: 'En caso de interrupción del suministro eléctrico, el equipo se inicia automáticamente una vez reestablecido.'
            },
            {
                title:'DESCONEXIÓN AUTOMÁTICA SLEEP',
                text: 'El microprocesador adecua gradualmente la temperatura a las necesidades corporales, previamente a la desconexión.'
            },
            {
                title:'PROGRAMACIÓN HORARIA COMBINADA ON-OFF',
                text: 'El programador digital permite seleccionar cualquiera de estas 4 combinaciones: ON, OFF, ON-OFF, OFF-ON.'
            },
            {
                title:'FILTRO DEODORIZADOR DE IONES',
                text: 'Reduciendo la oxidación mediante la generación de iones, este filtro neutraliza eficazmente los malos olores.'
            },
            {
                title:'WASH',
                text: 'Panel frontal extraíble y lavable.'
            }
        ]
    }
]

function Portfolio() {
    return (
        <>
        <section  id="portfolio" className="light">
                <div className="bg-light"></div>
                <div className="block">
                    <div className="container">
                    <div className="section-sub-title center">
                                <article className="section-title-body">
                                <h1 className="head-title">Conoce nuestros <span>productos</span></h1>
                                        <p className="head-text">
                                        Contamos con las mejores marcas de productos de repuestos y una línea completa de herramientas que facilitan su trabajo y estamos en la búsqueda permanente de nuevas tecnologías e innovaciones, para ayudar nuestros clientes. 
                                        </p>
                                </article>
                            </div>
                        <div className="row">
                                {portfolioAll.map((portfolio)=>(
                                    <PortfolioBlockModal
                                        key={portfolio._id.toString()} value={portfolio._id}
                                        portfolioTitle={portfolio.title} 
                                        portfolioInfo={portfolio.info} 
                                        portfolioSrc={portfolio.src} 
                                        portfolioCat={portfolio.category}
                                        portfolioContent={portfolio.content}
                                    />
                                ))}
                        </div>   
                    </div> 
                    <div className="clearfix"></div>
                </div>
        </section>
   
        </>
    )
}

export default Portfolio
