import React from 'react'
function About() {
    
    return (
        <>
            <section id="about" className="light">
            <div className="bg-light"></div>
            <div className="block">
                <div className="container">
                    <div className="row align-items-center  gx-5">
                        <div className="col-lg-6">
                            <div className="section-sub-title">
                                <article className="section-title-body">
                                    <h1 className="head-title"><span>Acerca de</span> Multifrio </h1>
                                    <p className="head-text">
                                    Somos una empresa de profesionales con una vasta experiencia, la que nos permite brindar un servicio de calidad y rapidez. Contamos con un stock de repuestos que asegura una respuesta ágil, marcando la diferencia con respecto a nuestra competencia. Además disponemos de vehículos totalmente equipados para llegar donde nuestros clientes nos requieran.
                                    </p>
                                    <ul className="list-unstyled list-bold d-lg-none d-xl-block">
                                        <li><i className="ion-checkmark"></i> Más de 20 años de experiencia en mantenimiento de equipos de aire acondicionado, sistemas de refrigeración comercial y semi-industrial.</li>
                                        <li><i className="ion-checkmark"></i> Herramientas y productos de repuestos de las mejores marcas.</li>
                                        <li><i className="ion-checkmark"></i> Todo que necesitas en productos de repuestos para refrigeración y herramientas  </li>
                                        <li><i className="ion-checkmark"></i> Tu casa climatizada para que tu única preocupación sea disfrutar de momentos increíbles con las personas que amas  </li>
                                    </ul>
                                </article>
                            </div>                      
                        </div>
                        <div className="col-lg-6">
                            <div className="about-img-block">
                                <img alt="" className="img-fluid" src="img/show/truck.jpg"/>
                                <div className="about-img lax   lax_preset_slideY:3500:-400">
                                    <img alt="" className="img-fluid" src="img/show/air-conditioning.jpg"/>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default About
