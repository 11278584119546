import React from 'react'
import {Link} from 'react-scroll'
function Welcome() {
    return (
        <section>
            <div className="block color-scheme-white light">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-9">
                            <div className="section-sub-title center">
                                <article className="section-title-body">
                                        <h1 className="head-title">Nos preocupa solucionar los problemas de nuestros <span>clientes</span> y hacer sus <span>vidas</span> más sencillas !</h1>
                                        <p className="head-text">
                                        Contamos con las mejores marcas de productos de repuestos y una línea completa de herramientas que facilitan su trabajo y estamos en la búsqueda permanente de nuevas tecnologías e innovaciones, para ayudar nuestros clientes.
                                        </p>
                                        <p>
                                            Compre también por Mercado Libre.
                                        </p>
                                            <a target='_blank' href='https://listado.mercadolibre.cl/_CustId_794903581?item_id=MLC937032958&category_id=MLC161557&seller_id=794903581&client=recoview-selleritems&recos_listing=true
' className="btn-default btn-move t1" smooth={false} duration={800}>Compre en línea</a>
                                        <div>   
                                            <Link to='portfolio' className="btn-default  btn-move" smooth={false} duration={800}>Productos</Link>&nbsp;
                                            <Link to='contact' className="btn-default active btn-move t1" smooth={false} duration={800}>Contactar</Link>
                                        </div>
                                </article>
                            </div>   
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Welcome
