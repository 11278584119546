import React, {useState} from 'react'
import {Link} from 'react-scroll'
import logo from './../../images/logo-big-shop.png'

function Navbar3() {
    const [navToggle, setnavToggle] = useState(true);

    return (
        <>

            <nav className="navbar navbar-default  navbar-expand-md light">
                <div className="navbar-header">
                <Link to='home'  className="header-logo"  smooth={false} duration={800}>
                                    <img src={logo} alt="logo"/>
                                </Link>
                                <button onClick={()=>setnavToggle(!navToggle)} type="button" className="navbar-toggle" data-bs-toggle="collapse" data-bs-target="#bs-example-navbar-collapse-1" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                                
                </div>
                <div className={navToggle?'collapse navbar-collapse':'collapse navbar-collapse show'}  id="bs-example-navbar-collapse-1">
                    <ul className="nav navbar-nav  navbar-right menu-item-5 light" id="navbar-full">
                        <li className="current home"><span className="light-layout"></span><Link to='home' className="link-item " smooth={false} duration={800} activeClass="active">Inicio</Link></li>
                        <li className="work"><span className="light-layout"></span><Link to='portfolio' className="link-item" smooth={false} duration={800} activeClass="active">Productos</Link></li>
                        <li className="service"><span className="light-layout"></span><Link to='services' className="link-item" smooth={false} duration={800} activeClass="active">Servicios</Link></li>
                        <li className="about"><span className="light-layout"></span><Link to='about' className="link-item" smooth={false} duration={800} activeClass="active">Quiénes somos</Link></li>                                            
                        <li className="contact"><span className="light-layout"></span><Link to='contact' className="link-item" smooth={false} duration={800} activeClass="active">Contacto</Link></li>
                    </ul>
                </div>
            </nav> 
        </>
    )
}

export default Navbar3
