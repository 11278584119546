function Products() {
    return (
        <ul id="grd">
            <li class="shrink"><a class="grd-cell" target='_blank' href='https://listado.mercadolibre.cl/_CustId_794903581?item_id=MLC937032958&category_id=MLC161557&seller_id=794903581&client=recoview-selleritems&recos_listing=true'>
                <img class="grd-img" src="img/products/aceite.png"/><span class="grd-overlay"></span><span class="grd-text">Aceite</span></a>
            </li>
            <li class="shrink"><a class="grd-cell" target='_blank' href='https://listado.mercadolibre.cl/_CustId_794903581?item_id=MLC937032958&category_id=MLC161557&seller_id=794903581&client=recoview-selleritems&recos_listing=true'>
                <img class="grd-img" src="img/products/aislación.png"/><span class="grd-overlay"></span><span class="grd-text">Aislación</span></a>
            </li>
            <li class="shrink"><a class="grd-cell" target='_blank' href='https://listado.mercadolibre.cl/_CustId_794903581?item_id=MLC937032958&category_id=MLC161557&seller_id=794903581&client=recoview-selleritems&recos_listing=true'>
                <img class="grd-img" src="img/products/anenometro.png"/><span class="grd-overlay"></span><span class="grd-text">Anemómetro</span></a>
            </li>
            <li class="shrink"><a class="grd-cell" target='_blank' href='https://listado.mercadolibre.cl/_CustId_794903581?item_id=MLC937032958&category_id=MLC161557&seller_id=794903581&client=recoview-selleritems&recos_listing=true'>
                <img class="grd-img" src="img/products/arbol-de-carga.png"/><span class="grd-overlay"></span><span class="grd-text">Árbol de carga</span></a>
            </li>
            <li class="shrink"><a class="grd-cell" target='_blank' href='https://listado.mercadolibre.cl/_CustId_794903581?item_id=MLC937032958&category_id=MLC161557&seller_id=794903581&client=recoview-selleritems&recos_listing=true'>
                <img class="grd-img" src="img/products/bomba-de-vacio.png"/><span class="grd-overlay"></span><span class="grd-text">Bomba de vacío</span></a>
            </li>
            <li class="shrink"><a class="grd-cell" target='_blank' href='https://listado.mercadolibre.cl/_CustId_794903581?item_id=MLC937032958&category_id=MLC161557&seller_id=794903581&client=recoview-selleritems&recos_listing=true'>
                <img class="grd-img" src="img/products/cintas.png"/><span class="grd-overlay"></span><span class="grd-text">Cintas</span></a></li>
            <li class="shrink"><a class="grd-cell" target='_blank' href='https://listado.mercadolibre.cl/_CustId_794903581?item_id=MLC937032958&category_id=MLC161557&seller_id=794903581&client=recoview-selleritems&recos_listing=true'>
                <img class="grd-img" src="img/products/gases-refigerantes.png"/><span class="grd-overlay"></span><span class="grd-text">Gases refrigerantes</span></a>
            </li>
            <li class="shrink"><a class="grd-cell" target='_blank' href='https://listado.mercadolibre.cl/_CustId_794903581?item_id=MLC937032958&category_id=MLC161557&seller_id=794903581&client=recoview-selleritems&recos_listing=true'>
                <img class="grd-img" src="img/products/lama-PVC.png"/><span class="grd-overlay"></span><span class="grd-text">Lama PVC</span></a>
            </li>
            <li class="shrink"><a class="grd-cell" target='_blank' href='https://listado.mercadolibre.cl/_CustId_794903581?item_id=MLC937032958&category_id=MLC161557&seller_id=794903581&client=recoview-selleritems&recos_listing=true'>
                <img class="grd-img" src="img/products/motocompresor.png"/><span class="grd-overlay"></span><span class="grd-text">Motocompresor</span></a>
            </li>
            <li class="shrink"><a class="grd-cell" target='_blank' href='https://listado.mercadolibre.cl/_CustId_794903581?item_id=MLC937032958&category_id=MLC161557&seller_id=794903581&client=recoview-selleritems&recos_listing=true'>
                <img class="grd-img" src="img/products/motoventilador-axial.png"/><span class="grd-overlay"></span><span class="grd-text">Motoventilador axial</span></a>
            </li>
            <li class="shrink"><a class="grd-cell" target='_blank' href='https://listado.mercadolibre.cl/_CustId_794903581?item_id=MLC937032958&category_id=MLC161557&seller_id=794903581&client=recoview-selleritems&recos_listing=true'>
                <img class="grd-img" src="img/products/pistola-pulverizadora.png"/><span class="grd-overlay"></span><span class="grd-text">Pistola pulverizadora</span></a>
            </li>
            <li class="shrink"><a class="grd-cell" target='_blank' href='https://listado.mercadolibre.cl/_CustId_794903581?item_id=MLC937032958&category_id=MLC161557&seller_id=794903581&client=recoview-selleritems&recos_listing=true'>
                <img class="grd-img" src="img/products/termostato.png"/><span class="grd-overlay"></span><span class="grd-text">Termostato</span></a>
            </li>
        </ul>
    );
};

export default Products;