import React from 'react'
const services = [
    {
        _id:'001',
        classIcon:'snow',
        title:'Climatización',
        text:'Su casa con la temperatura ideal por todo el año. '
    },
    {
        _id:'002',
        classIcon:'flash',
        title:'Soluciones rápidas',
        text:'Línea completa de repuestos de las mejores marcas a pronta entrega.'
    },
    {
        _id:'003',
        classIcon:'hammer',
        title:'Trabajo en terreno',
        text:'Técnicos con mas de 20 años de experiencia en refrigeración y aire condicionado.'
    },
    {
        _id:'004',
        classIcon:'paper-plane',
        title:'Mejoramos tu productividad',
        text:'Contamos con una línea completa de herramientas de las mejores marcas que te hacen ahorrar tiempo en sus trabajos.'
    },
    {
        _id:'005',
        classIcon:'hourglass',
        title:'Ahorra tiempo',
        text:'Proporcionamos atención telefónica, en línea y despachamos para todo el país.'
    },
    {
        _id:'006',
        classIcon:'settings',
        title:'Soluciones personalizadas',
        text:'Soporte completo para su proyecto de climatización.'
    }
]
function Service() {
    return (
        <>
        <section  id="services" className="light">
            <div className="bg-light"></div>
                

                <div className="block">
                    <div className="container">
                            <div className="section-sub-title center">
                                <article className="section-title-body">
                                    <h1 className="head-title"> Conoce nuestros <span>servicios</span> </h1>
                                </article>
                            </div>
                        <div className="row">
                            {services.map((service)=>(
                            <div className="col-lg-4 col-md-6"  key={service._id}>
                                <div className="block-feature-icon  center dark">
                                    <div className="icon hi-icon-color hi-icon-effect">
                                        <span className="hi-icon"><ion-icon name={service.classIcon}></ion-icon></span>
                                    </div>
                                    <div className="block-title">
                                        <h4>{service.title}</h4> 
                                        <p>{service.text}</p>
                                    </div>
                                </div>
                            </div>
                            ))}
                        </div>
                    </div>
                </div>
        </section>
        </>
    )
}

export default Service
