import React from 'react';
import ContactForm from './../Contact/ContactForm';
import { SocialIcon } from 'react-social-icons';


function Contact() {
    return (
        <>
        <section  id="contact" className="light">
          <div className="bg-light"></div>
                <div className="block">
                    <div className="container">
                        <div className="row ">
                            <div className="col-md-6">
                                <div className="section-sub-title">
                                    <article className="section-title-body">
                                        <h1 className="head-title">Contáctanos </h1>
                                    </article>
                                </div>
                                <div className="widget-block dark">
                                    <h4 className="widget-title"><i className="ion-pound"></i>Multifrio</h4>
                                    <h4 className="widget-title"><i className="ion-map"></i>Avda Italia 2120, Mirador Cardonal, Puerto Montt, Región de Los Lagos</h4>
                                    <br/> 
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="widget-block dark">
                                            <h4 className="widget-title"><i className="ion-android-call"></i>Teléfonos:</h4>
                                            <p>
                                                +56 9 8805 0400<br/>
                                                +56 9 9207 9705<br/>
                                                +56 9 7211 7648<br/>
                                                +56 (65) 231 9999
                                            </p>
                                        </div>
                                        <ul className="contact-icon list-unstyled">
                                            <li>
                                                <SocialIcon target="_blank" url="https://www.facebook.com/MultifrioPuertoMontt/" />
                                            </li>
                                            <li>
                                                <SocialIcon target="_blank" url="https://www.instagram.com/multifrio_/" />
                                            </li>
                                            <li>
                                                <SocialIcon target="_blank" url="https://www.linkedin.com/company/comercialmultifrio/" />
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="widget-block dark">
                                            <h4 className="widget-title"><i className="ion-email"></i>Correos:</h4>
                                            <p> ventas@multifrio.cl<br/>
                                                ventas2@multifrio.cl<br/>
                                                ventas3@multifrio.cl
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 align-items-center">
                                <div className="form-light">
                                    <ContactForm/>
                                </div>
                                
                            </div>                                  
                        </div>
                    </div>
                </div>
            
        </section>
        </>
    )
}

export default Contact
