import React from 'react'

function Map() {
    return (
        <>
         <div className="google-map">

            <iframe title="My Address" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5979.519024555313!2d-72.973048!3d-41.466131!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4fce50858b03e5b4!2sMultifrio!5e0!3m2!1ses-419!2scl!4v1636935869206!5m2!1ses-419!2scl"></iframe>

        </div>   
        </>
    )
}

export default  Map
