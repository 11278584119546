import React from 'react';
import Header from '../components/Header/Header';
import SimpleSlider from '../components/Sliders/Slick-slider'
import Welcome from '../components/Welcome/Welcome';
import About from './../components/About/About';
import Service from '../components/Service/Service';
import Portfolio from '../components/Portfolio/Portfolio';
import Products from '../components/Products/Products';
import Contact from '../components/Contact/Contact';
import Map from '../components/Map';
import Footer from '../components/Footer/Footer';

function Home() {
  return (
    <>
      <div  id="home" className="full-screen-block carousel-with-dots dotted-left">
        <Header/>
        <SimpleSlider/>
      </div>
      <Welcome/>
      <Portfolio/>
      <Products/>
      <Service/>
      <About/>
      <Contact/>
      <Map/>
      <Footer/>
    </>
  );
}

export default Home;