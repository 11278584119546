import React from 'react';
import { SocialIcon } from 'react-social-icons';

function Footer() {
    return (
        <>
        <footer id="footer">
            <div className="container light">
                <div className="row">
                <div className="col-md-12">
                        <article className="footer-info-block">
                            <div className="brands-desktop">
                                <ul className="list-unstyled flickr">
                                    <li><img src="img/brands/csrel.jpg" className="img-fluid" alt=""/></li>
                                    <li><img src="img/brands/daitsu.jpg" className="img-fluid" alt=""/></li>
                                    <li><img src="img/brands/danfoss.jpg" className="img-fluid" alt=""/></li>
                                    <li><img src="img/brands/fujitsu.jpg" className="img-fluid" alt=""/></li>
                                    <li><img src="img/brands/global.jpg" className="img-fluid" alt=""/></li>
                                    <li><img src="img/brands/k11.jpg" className="img-fluid" alt=""/></li>
                                    <li><img src="img/brands/sanhua.jpg" className="img-fluid" alt=""/></li>
                                    <li><img src="img/brands/suniso.jpg" className="img-fluid" alt=""/></li>
                                    <li><img src="img/brands/value.jpg" className="img-fluid" alt=""/></li>
                                    <li><img src="img/brands/castel.jpg" className="img-fluid" alt=""/></li>
                                </ul>
                            </div>
                            <div className="brands-mobile">
                                <ul className="list-unstyled flickr">
                                    <li><img src="img/brands/csrel.jpg" className="img-fluid" alt=""/></li>
                                    <li><img src="img/brands/daitsu.jpg" className="img-fluid" alt=""/></li>
                                    <li><img src="img/brands/danfoss.jpg" className="img-fluid" alt=""/></li>
                                    <li><img src="img/brands/fujitsu.jpg" className="img-fluid" alt=""/></li>
                                    <li><img src="img/brands/global.jpg" className="img-fluid" alt=""/></li>
                                </ul>
                                <ul className="list-unstyled flickr">
                                    <li><img src="img/brands/k11.jpg" className="img-fluid" alt=""/></li>
                                    <li><img src="img/brands/sanhua.jpg" className="img-fluid" alt=""/></li>
                                    <li><img src="img/brands/suniso.jpg" className="img-fluid" alt=""/></li>
                                    <li><img src="img/brands/value.jpg" className="img-fluid" alt=""/></li>
                                    <li><img src="img/brands/castel.jpg" className="img-fluid" alt=""/></li>
                                </ul>
                            </div>
                        </article>
                    </div>
                </div>
                
                <div className="footer-bottom">
                    <div className="row">

                        <div className="col-md-8 order-1 col-xs-12 col-sm-8">
                            <ul className="footer-icon list-unstyled">
                                <li>
                                    <SocialIcon target="_blank" url="https://www.facebook.com/MultifrioPuertoMontt/" />
                                </li>
                                <li>
                                    <SocialIcon target="_blank" url="https://www.instagram.com/multifrio_/" />
                                </li>
                                <li>
                                    <SocialIcon target="_blank" url="https://www.linkedin.com/company/comercialmultifrio/" />
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-4 col-xs-12 col-sm-4">
                            <div className="footer-logo">
                                <img src="img/logo-big-shop1.png" alt=""/>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </footer>
        </>
    )
}

export default Footer
